<template>
    <div class="display-block">
        <v-card>
            <v-card-text>
                <h3>Category Details:</h3>
                <row-item label="Title  " :value="category.title"></row-item>
                <v-flex xs12 sm4 md3 class="pa-1" width="200">
                    <image-card :src="category.image.path" v-if="category.image"></image-card>
                </v-flex>
                <row-item label="Products under this category  "></row-item>
                <ul class="left-padding font-format" v-for="product in products" :key="product.title">
                    <li>{{product.title}}</li>
                </ul>
            </v-card-text>
                <v-flex text-right><v-btn @click="$router.go(-1)" class="ma-2" :style="theme">Back</v-btn></v-flex>
        </v-card>
    </div>
</template>

<script>
import RowItem from '@/components/RowItem'
import appConstants from '@/utils/appConstants'
import axios from "axios";
export default {
    components: {
        RowItem,
    },
    data() {
        return {
            id:0,
            items:[],
            category: {
                id: "",
                title: "",
                image: ""
            },
            products:[]
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.id = this.$route.params.id
            this.category = await this.getItem(appConstants.CATEGORIES_API + "/" + this.id)
            this.products=await this.getItem(appConstants.CATEGORYWISE_PRODUCTS+"/"+this.category._id)
        },
        getImage(image){
            if(!this.img){
                return require('@/assets/'+image)}
        }
    }
}
</script>

<style scoped>
.left-padding{
    padding-left: 2%;
}

.font-format{
    font-weight: 500;
}
</style>
